<template>
    <!-- 导航 -->
    <page-head title="AC目标报告" />
    <content-bg>
        <template v-slot:content>
            <data-report-structure>
                <template v-slot:left v-if="pageType === 'player'">
                    <select-style :list="userTeamList" label="name" key="id" v-model:value="teamId"
                                  @changeFn="changeFn"></select-style>
                </template>
                <template v-slot:center>{{playerName}}</template>
                <template v-slot:right>{{$moment().format('YYYY')}} 01/01 - {{$moment().format('MM/DD')}}</template>
                <template v-slot:content>
                    <div class="barBox" v-for="(item, index) in dataSource" v-if="filterArr.length && GPSData.length">
                        <div class="title">
                            <p>{{ item.displayName }}</p>
                        </div>
                        <bar-chart
                            :xData="item.xData"
                            :yData="item.seriesData"
                            :name="item.displayName"
                            :diff-option="diffOption"
                            class="bar_chart"></bar-chart>
                    </div>
                    <no-data v-else></no-data>
                </template>
            </data-report-structure>
        </template>
    </content-bg>
</template>

<script>
import {useRoute} from "vue-router";
import {getCurrentInstance, reactive, toRefs} from "vue";
import {useStore} from "vuex";
import barChart from '../components/bar-chart'

export default {
    name: "details",
    components: {
        barChart
    },
    setup() {
        const route = useRoute()
        const {proxy} = getCurrentInstance()
        const store = useStore()

        const state = reactive({
            playerName: route.query.name,
            pageType: route.query.pageType,
            filterArr: [],

            GPSData: [],
            dateArr: [],

            dataSource: [],
            userTeamList: [],
            teamId: {},
            nowWeek: 0,
            weekLength: 4, // 前四周后四周

            diffOption: {
                'series[1]': `{
                        type: 'line',
                        zlevel: 100,
                        symbol: 'circle',
                        symbolSize: 0.01,
                        hoverAnimation: false,
                        itemStyle: {
                            color: '#78BBF0',
                            lineStyle:{
                                width: 3,
                            }
                        },
                        label: {
                            show: true,
                            distance: 30,
                            formatter:  (params) => {
                                let number = params.value;
                                let increase = params.data.increase
                                let html = '{label|' + increase + ' ' + '}'
                                if(increase){
                                    if(increase.substr(0,1)=='-'){
                                        html= '{num|' + increase + ' ' + '}'
                                    }
                                }
                                return html
                            },
                            rich: {
                                label: {
                                    align: 'center',
                                    color: '#85C934',
                                    fontFamily: 'PingFangMedium',
                                    fontSize: 26
                                },
                                num: {
                                    align: 'center',
                                    color: '#E55045',
                                    fontFamily: 'PingFangMedium',
                                },
                                normal: {
                                    align: 'center',
                                    color: '#000000',
                                    fontFamily: 'AkrobatRegular',
                                }
                            },
                        },
                        data: this.yData,
                    }`
            },
        });


        //  获取gps 筛选项
        const getFilter = () => {
            const {id} = store.state.teamData;
            let data = {
                teamId: id
            }
            proxy.$server.getGpsFilter(data).then(res => {
                if (res.code === 200) {
                    if (res.data && res.data.length) {
                        let arr = [];
                        res.data.map(item => {
                            if (item.aim) {
                                arr.push(item)
                            }

                        })
                        state.filterArr = arr;
                        getData()
                    }
                }
            })
        }

        const getNowWeek = () => {
             proxy.$server.nowWeek().then(res => {
                 state.nowWeek = res.data;
             })
        }
        getNowWeek()

        const getData = () => {
            const {id} = store.state.teamData;
            let data = {
                playerId: route.query.id,
                // playerId: '6100f3c24b2c714800ef6f8d',
                teamId: id,
                startDate: proxy.$Config.currentYearFirstDay,
                endDate: proxy.$moment().format('YYYY/MM/DD')
            }
            proxy.$server.getAIMReport(data).then(res => {
                state.filterArr.forEach(item => {
                    item.seriesData = []
                    item.xData = []
                })
                state.GPSData = res.data || []
                if (!res.data || !res.data.length) {
                    return
                }

                state.GPSData.forEach((item) => {
                    if (!((state.nowWeek + state.weekLength) >= item.weekIndex && (state.nowWeek - state.weekLength) <= item.weekIndex)) return;
                    state.filterArr.forEach(gps => {
                        const currentGps = item.data ? item.data.find(dataValue => dataValue.slug === gps.slug) : {}
                        if (currentGps && currentGps.value) {
                            gps.seriesData.push({
                                value: Number(currentGps.value).toFixed(gps.decimal),
                                increase: currentGps.increase,
                                timeFormat: gps.timeFormat,
                                percentage: gps.percentage,
                                name: gps.displayName
                            })
                        } else {
                            gps.seriesData.push({
                                value: '',
                                increase: currentGps.increase,
                                timeFormat: gps.timeFormat,
                                percentage: gps.percentage,
                                name: gps.displayName
                            })
                        }
                        gps.xData.push(item.indexShow)
                    })
                })

                state.dataSource = JSON.parse(JSON.stringify(state.filterArr))
            })
        }

        if (state.pageType === 'player') {
            const params = {
                userId: route.query.id,
                userType: 'player'
            }
            proxy.$server.getUserTeams(params)
                .then(res => {
                    state.userTeamList = res.data;
                    changeFn(state.userTeamList[0])
                })
        } else {
            getFilter();
        }

        const changeFn = (item) => {
            state.teamId = item;
            store.commit('setTeamData', item)
            getFilter();
        }

        return{
            ...toRefs(state),

            changeFn
        }
    }
}
</script>

<style scoped lang="scss">
.barBox {
    height: 680px;
    width: 100%;
    .title {
        position: relative;
        p{
            padding-top: 24px;
            padding-bottom: 18px;
            font-size: 22px;
            line-height: 31px;
            color: rgba(255, 255, 255, .8);
            font-family: PingFangMedium;
            padding-left: 50px;
        }
    }

    .bar_chart {
        height: calc(100% - 80px);
        width: 100%;
    }
}
</style>